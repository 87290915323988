.card:hover{
    box-shadow: 1px 1px 8px grey;
}
.listing{
    list-style-type: none;
    width: 100%;
    padding: 40px 0px;
}
.listing > li{
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    color: blue
}